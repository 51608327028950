<template>
  <div>
    <div class="secondary white--text top-bar">
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title>
            <router-link to="/">
              <v-img
                class="me-2 logo d-md-none d-block"
                width="98"
                src="@/assets/images/logos/Asaan-shopping-logo.png"
                alt=""
              ></v-img>
            </router-link>
            <div class="mr-2 d-md-block d-none">
              <!-- <v-icon class="mr-2 white--text" small>mdi-phone-classic</v-icon>
              <span class="text-sm mr-5">+92-992-414434</span> -->
              <v-icon class="mr-2 white--text" small>mdi-cellphone</v-icon>
              <span class="text-sm">+92-316-5730822</span>
            </div>
          </v-toolbar-title>
          <v-toolbar-title class="d-md-none d-block">
            <div class="d-md-none d-block">
              <!-- <v-icon class="mr-2 white--text" small>mdi-phone-classic</v-icon>
              <span class="text-sm">+92-992-414434</span> -->
              <v-icon class="mr-2 white--text" small>mdi-cellphone</v-icon>
              <span class="text-sm">+92-316-5730822</span>
            </div>
          </v-toolbar-title>
          <v-toolbar-title class="d-md-inline-block d-none">
            <router-link to="/contact-us">
              <span class="white--text mr-5 d-md-inline-block d-none"
                >Contact Us</span
              >
            </router-link>
          </v-toolbar-title>
        </div>
      </v-container>
    </div>
    <v-app-bar
      height="72"
      app
      scroll-off-screen
      class="sub-topbar"
      color="white py-1"
    >
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <v-toolbar-title class="d-flex align-center">
            <router-link to="/">
              <v-img
                class="me-2 logo d-md-block d-none"
                width="98"
                src="@/assets/images/logos/Asaan-shopping-logo.png"
                alt=""
              ></v-img>
            </router-link>
            <div class="dropdown-ecommerce">
              <HeaderNavbar />
            </div>
          </v-toolbar-title>
          <v-col cols="12" md="7">
            <div class="search-bar d-flex p-relative">
              <v-text-field
                class=""
                placeholder="Searching For"
                filled
                rounded
                dense
                prepend-inner-icon="mdi-magnify"
                @keyup.enter="search"
                v-model="searchValue"
              >
                <template v-slot:append v-if="isCatgSelected"
                  ><v-chip
                    dense
                    small
                    close
                    @click:close="setCategory"
                    class="text-capitalize"
                    >{{ isCatgSelected.replace(/_/g, " ") }}</v-chip
                  >
                </template>
              </v-text-field>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="text-capitalize search-bar-dropdown"
                    @click="search"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </div>
          </v-col>
          <div class="">
            <div class="d-md-block d-none">
              <v-menu offset-y >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    depressed
                  >
                    Download App
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item link href="https://play.google.com/store/apps/details?id=com.asanshopping" target="_blank">
                    <v-list-item-icon>
                      <v-icon>mdi-android</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Andriod App</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item link>
                    <v-list-item-icon>
                      <v-icon>mdi-apple-ios</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>IOS App</v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
              <!-- <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    fab
                    small
                    class="mr-3"
                    color="grey lighten-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>

                <base-card>
                  <div class="px-3 px-md-10 py-8">
                    <h3 class="mb-2 text-center">Welcome To Ecommerce</h3>
                    <h5
                      class="font-600 grey--text text--darken-3 text-sm mb-9 text-center"
                    >
                      Log in with email & password
                    </h5>
                    <p class="text-14 mb-1">Email or Phone Number</p>
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      placeholder="example@mail"
                      class="mb-4"
                    ></v-text-field>
                    <p class="text-14 mb-1">Password</p>
                    <v-text-field
                      outlined
                      dense
                      type="password"
                      hide-details
                      placeholder="example@mail"
                      class="mb-4"
                    ></v-text-field>
                    <v-btn
                      block
                      color="primary"
                      class="text-capitalize font-600"
                      >Login</v-btn
                    >
                    <v-col cols="10" lg="8" class="mx-auto">
                      <div class="d-flex align-center my-1">
                        <v-divider></v-divider>
                        <span class="mx-4">on</span>
                        <v-divider></v-divider>
                      </div>
                    </v-col>
                    <v-btn
                      block
                      dark
                      color="indigo"
                      class="text-capitalize font-600 mb-4"
                    >
                      <v-icon left class="me-3">mdi-facebook</v-icon>
                      Continue with facebook
                    </v-btn>
                    <v-btn
                      block
                      dark
                      color="blue darken-2"
                      class="text-capitalize font-600 mb-4"
                    >
                      <v-icon left class="me-3">mdi-google</v-icon>
                      Continue with Google
                    </v-btn>
                    <div class="text-14 text-center my-3">
                      Don't have account?
                      <router-link
                        to="/sign-up"
                        class="grey--text text--darken-4 font-600"
                        >Sign Up</router-link
                      >
                    </div>
                  </div>
                  <div class="py-4 grey lighten-2">
                    <div class="text-center">
                      <span class="grey--text text--darken-1"
                        >Forgot Your Password
                        <router-link
                          to="/"
                          class="ms-2 grey--text text--darken-4 font-600"
                          >Reset It</router-link
                        >
                      </span>
                    </div>
                  </div>
                </base-card>
              </v-dialog> -->
              <!-- cartCount  -->
              <!-- <v-badge
                bordered
                color="error"
                :content="
                  getCartProducts.length == 0 ? '0' : getCartProducts.length
                "
                overlap
              >
                <v-btn
                  @click="drawer = true"
                  elevation="0"
                  fab
                  color="grey lighten-2"
                  small
                >
                  <v-icon color="">mdi-cart</v-icon>
                </v-btn>
              </v-badge> -->
            </div>
          </div>
        </div>
      </v-container>
    </v-app-bar>
    <v-app-bar class="navbar white">
      <div class="container">
        <div class="d-flex justify-space-between align-center">
          <v-btn
            elevation=""
            color="grey lighten-2"
            class="text-capitalize"
            :class="{ 'v-hidden': $route.path == '/home-two' }"
            @click="toggleNavbar"
            v-on-clickaway="away"
          >
            <v-icon left> mdi-view-dashboard </v-icon>
            Categories
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
          <div
            id="navbar-toggle-dropdown"
            class="navbar-toggle-dropdown p-absolute"
            :class="{ open: isToggleNavbar }"
          >
            <Navbar />
          </div>
          <div>
            <ul class="navbar-nav navigation-navbar d-flex flex-row">
              <li class="nav-item">
                <router-link class="nav-link" to="/brandhome">
                  <p class="mb-0">Home</p>
                </router-link>
              </li>
              <li class="nav-item me-3">
                <a class="nav-link">All Categories</a>
                <ul>
                  <li
                    class="text-capitalize"
                    v-for="(menu, index) in menus"
                    :key="index"
                  >
                    <a class="d-flex justify-space-between align-center">
                      <p class="mb-0">{{ menu.category }}</p>
                      <v-icon>mdi-menu-right</v-icon>
                    </a>
                    <div class="navbar-submenu">
                      <ul>
                        <li
                          v-for="submenu in menu.subcategories"
                          :key="submenu.subcategory"
                          @click="
                            brandProducts(menu.category, submenu.subcategory)
                          "
                        >
                          <a class="d-flex justify-space-between align-center">
                            <p class="mb-0 text-capitalize">
                              {{ submenu.subcategory }}
                            </p>
                          </a>
                          <!-- <router-link to="/sale-page-two">
                            <p class="mb-0">Unstitched</p>
                          </router-link> -->
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/blogs">
                  <p class="mb-0">Blogs</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/stores">
                  <p class="mb-0">Registered Stores</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/about">
                  <p class="mb-0">About Us</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact-us">
                  <p class="mb-0">Contact Us</p>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </v-app-bar>
    <v-bottom-navigation
      v-model="value"
      class="d-md-none d-flex mobile-navigation-bottom"
      color="primary"
      fixed
    >
      <v-btn to="/">
        <span class="">Home</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn to="/brandHome">
        <span>All Brands</span>
        <v-icon>mdi-gift</v-icon>
      </v-btn>

      <v-btn to="/contact-us">
        <span>Contact Us</span>
        <v-icon>mdi-contacts</v-icon>
      </v-btn>
      <v-btn @click="menuDrawer = !menuDrawer">
        <span>Menu</span>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <!-- <v-btn to="/mobile-categories">
      <span>Category</span>
      <img v-svg-inline class="icon mr-0" src="@/assets/images/icons/category.svg" alt="" />
    </v-btn>

    <v-btn to="/cart">
      <span>Cart</span>
      <img v-svg-inline class="icon mr-0" src="@/assets/images/icons/bag.svg" alt="" />
    </v-btn>

    <v-btn to="/view-profile">
      <span>Account</span>

      <img v-svg-inline class="icon mr-0" src="@/assets/images/icons/user-2.svg" alt="" />
    </v-btn> -->
    </v-bottom-navigation>
    <v-navigation-drawer
      v-model="menuDrawer"
      fixed
      temporary
      right
      width="320"
      class="z-999"
    >
      <div class="secondary-siebar-content">
        <v-subheader>
          <v-img
            class="me-2 mt-1 logo"
            max-height="50"
            max-width="100"
            src="@/assets/images/logos/Asaan-shopping-logo.png"
            alt=""
          ></v-img>
          <v-spacer></v-spacer>
          <v-btn icon color="" @click="menuDrawer = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-subheader>
        <v-divider class="mt-2"></v-divider>
      </div>
      <v-list>
        <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/brandHome">
         <v-list-item-icon>
           <v-icon>mdi-gift</v-icon>
        </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>All Brands</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/blogs">
          <v-list-item-icon>
           <v-icon>mdi-newspaper</v-icon>
        </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Blogs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/stores">
           <v-list-item-icon>
           <v-icon>mdi-store-check</v-icon>
        </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Registered Stores</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/about">
           <v-list-item-icon>
           <v-icon>mdi-information</v-icon>
        </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>About Us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/contact-us">
         <v-list-item-icon>
         <v-icon>mdi-contacts</v-icon>
        </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         
      </v-list>
       <template v-slot:append>
        <div class="d-flex justify-center mb-1">
          <div>
           <a href="https://play.google.com/store/apps/details?id=com.asanshopping" target="_blank">
                      <v-img
                        max-width="130"
                        class="mr-3"
                        src="@/assets/images/app-store/google-play.png"
                      ></v-img>
                      </a>
          </div>
           <!-- <v-list-item link href="https://play.google.com/store/apps/details?id=com.asanshopping" target="_blank">
                    <v-list-item-icon>
                      <v-icon>mdi-android</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Andriod App</v-list-item-title>
                  </v-list-item> -->
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import HeaderNavbar from "@/views/home/header-navbar";
import Navbar from "@/views/home/navbar";
import { directive as onClickaway } from "vue-clickaway";

export default {
  name: "BaseAppBar",
  directives: {
    onClickaway: onClickaway,
  },
  created() {
    this.$store.dispatch("brandsMenu").then((res) => {
      this.menus = [...res.data.Menus];
    });
  },
  components: {
    HeaderNavbar,
    Navbar,
  },

  computed: {
    ...mapGetters(["getSelectedCategory"]),
    // start asanshopping.pk
    isCatgSelected() {
      return this.getSelectedCategory;
    },
    searchData() {
      return this.searchValue.replace(/\s+/g, " ").trim().split(" ");
    },
    // currentRouteName() {
    //   return this.$route.name;
    // },
    searchStore() {
      if (
        this.$route.name == "Home" ||
        this.$route.name == "Details" ||
        this.$route.name == "Search"
      )
        return "all";
    },
    // end asanshopping.pk
  },
  data: () => ({
    value:1,
    menuDrawer: false,
    searchValue: "",
    colorNav: false,
    dialog: false,
    isToggleNavbar: false,
    group: null,
    menus: [],
  }),

  methods: {
    // start asanshopping.pk
    // ...mapMutations(["setSelectedCategory","setPageNumber"]),
    ...mapMutations([
      "setSelectedCategory",
      "setBrandCategory",
      "setBrandSubCategory",
    ]),
    brandProducts(category, subCategory) {
      this.setBrandCategory(category);
      this.setBrandSubCategory(subCategory);
      // this.$router.push('/products')
      this.move({
        name: "Brand Products",
        query: {
          category: category,
          sub: subCategory,
        },
      });
    },
    setCategory() {
      this.$nextTick(function () {
        this.setSelectedCategory(null);
      });
    },
    move(params) {
      // get comparable fullPaths
      let from = this.$route.fullPath;
      let to = this.$router.resolve(params).route.fullPath;

      if (from === to) {
        // handle any error due the redundant navigation here
        // or handle any other param modification and route afterwards
        return;
      }

      // route as expected
      // this.setSelectedCategory(null);
      // this.setPageNumber(1);
      this.$router.push(params);
    },
    search() {
      if (this.searchValue) {
        this.move({
          name: "Search",
          query: {
            search: this.searchValue,
            stores: this.searchStore,
          },
        });
      }
    },
    changeview() {
      this.searchValue = "";
      // this.setPageNumber(1);
      this.setSelectedCategory(null);
      this.$router.push({
        name: "Home",
      });
    },
    // end asanshopping.pk
    toggleNavbar() {
      if (this.$route.path == "/") {
        this.isToggleNavbar = false;
      } else {
        this.isToggleNavbar = !this.isToggleNavbar;
      }
    },
    away() {
      this.isToggleNavbar = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$z-index-sub-topbar: 2;
$md: 959px;
$z-99: 99;
.z-999 {
  z-index: 999;
}

.v-list-item {
  min-height: 34px;
}
.top-bar {
  height: 40px;
  display: flex;
  align-items: center;
}
// .sale-page-one {
//   .sub-topbar {
//     &.v-app-bar--is-scrolled {
//       position: relative;
//       .dropdown-ecommerce {
//         display: none;
//       }
//     }
//   }

//   .navbar {
//     &.v-app-bar--is-scrolled {
//       top: 0;
//       margin-bottom: 0px;
//     }
//   }
// }
// .sale-page-two {
//   .sub-topbar {
//     &.v-app-bar--is-scrolled {
//       position: relative;
//       .dropdown-ecommerce {
//         display: none;
//       }
//     }
//   }

//   .navbar {
//     &.v-app-bar--is-scrolled {
//       top: 0;
//       margin-bottom: 0px;
//     }
//   }
// }
.sub-topbar {
  position: relative;
  box-shadow: none !important;
  width: 100%;
  height: 80px !important;
  z-index: $z-99;
  .dropdown-ecommerce {
    display: none;
  }
  &.v-app-bar--fixed {
    position: unset;
  }
  // z-index: $z-index-sub-topbar;

  &.v-app-bar--is-scrolled {
    position: fixed;
    top: 0;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    .dropdown-ecommerce {
      display: block;
    }
  }
  @media (max-width: $md) {
  }
}

.navbar {
  height: 60px;
  width: 100%;
  z-index: 2;
  box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
  &.v-app-bar--is-scrolled {
    position: relative;
    top: 64px;
    margin-bottom: 64px;
    box-shadow: rgb(43 52 69 / 10%) 0px 16px 16px -16px !important;
    // box-shadow: none !important;
  }
  @media (max-width: 992px) {
    display: none;
  }
}
.search-bar {
  ::v-deep .v-input__slot {
    border: 1px solid rgb(218, 225, 231);
    background-color: #fff !important;
  }
  .search-bar-dropdown {
    height: 39px;
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 2px;
    border-radius: 22px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 1px solid rgb(218, 225, 231) !important;
    box-shadow: none !important;
    @media (max-width: $md) {
      display: none;
    }
  }
  ::v-deep .v-text-field__details {
    display: none;
  }
}

// toggleNavbarButton
.navbar-toggle-dropdown {
  z-index: 98;
  display: none;
  &.open {
    display: block;
    top: 64px;
  }
}
.v-hidden {
  visibility: hidden;
}
.empty-cart-sidebar {
  height: 80vh;
}
</style>
