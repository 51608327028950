// Imports
import Vue from "vue";
import Router from "vue-router";
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "Home",
      meta: {
        layout: 'masterLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/Home.vue"),
    },
    {
      path: "/details",
      name: "Details",
      meta: {
        layout: 'masterLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/Details.vue"),
    },
    {
      path: "/search",
      name: "Search",
      meta: {
        layout: 'masterLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/SearchResults.vue"),
    },
    {
      path: "/contact-us",
      name: "Contact Us",
      meta: {
        layout: 'masterLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/ContactUs.vue"),
    },
    {
      path: "/privacy",
      name: "Privacy",
      meta: {
        layout: 'masterLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/Privacy.vue"),
    },
    {
      path: "/stores",
      name: "Stores",
      meta: {
        layout: 'masterLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/Stores.vue"),
    },
    {
      path: "/blogs",
      name: "Blogs",
      meta: {
        layout: 'masterLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/Blogs.vue"),
    },
    {
      path: "/about",
      name: "About",
      meta: {
        layout: 'masterLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/About.vue"),
    },
    {
      path: "/terms&conditions",
      name: "Terms & Conditions",
      meta: {
        layout: 'masterLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/TermsCondition.vue"),
    },
    {
      path: "/brandHome",
      name: "Brand Home",
      meta: {
        layout: 'brandsLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../views/BrandHome.vue"),
    },
    {
      path: "/subCategory",
      name: "Brand Category",
      meta: {
        layout: 'brandsLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../components/brands/subCategory.vue"),
    },
    {
      path: "/products",
      name: "Brand Products",
      meta: {
        layout: 'brandsLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../components/brands/products.vue"),
    },
    {
      path: "/productDetails",
      name: "Product Details",
      meta: {
        layout: 'brandsLayout',
        requiresVisitor: true,
      },
      component: () =>
        import(/* webpackChunkName: "auth" */ "../components/brands/productDetails.vue"),
    },

  ]
});
// router.beforeEach((to, from, next) => {

//   document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`
//   next()
// })
export default router;
