import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import  store  from "./store";
import vuetify from "@/plugins/vuetify";
import VueGtag from "vue-gtag";
import "./plugins";
import "@/assets/scss/_global.scss";
import VueCarousel from "vue-carousel";
Vue.config.productionTip = false;
Vue.use(VueGtag, {
  config: {
    id: "UA-221296842-1",
  }

});


// plugin svgrender 
// import Vue plugin
import VueSvgInlinePlugin from "vue-svg-inline-plugin";

// import polyfills for IE if you want to support it
import "vue-svg-inline-plugin/src/polyfills";
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin);

// start Social Sharing plugin
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);
// end Social Sharing plugin 

// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
	attributes: {
		data: [ "src" ],
		remove: [ "alt" ]
	}
});
import masterLayout from './layouts/masterLayout.vue'
import brandsLayout from './layouts/brandsLayout.vue'
Vue.component('masterLayout',masterLayout)
Vue.component('brandsLayout',brandsLayout)
// end:: svgrender 



import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  
  Vue.use(VueCarousel);
  Vue.component(VueSlickCarousel)
  Vue.component("VueSlickCarousel", VueSlickCarousel);
 
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
