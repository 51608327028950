<template>
  <vue-pull-refresh :on-refresh="onRefresh" :config="config">
    <component :is="layout">
      <router-view />
    </component>
    <!-- <base-view /> -->
  </vue-pull-refresh>
</template>

<script>
import VuePullRefresh from "vue-pull-refresh";
export default {
  name: "App",
  data() {
    return {
      config: {
        startLabel: "Getting Ready...",
        readyLabel: "Release to Refresh",
        loadingLabel: "Refreshing...",
      },
    
    };
  },
     metaInfo() {
    return {
      meta: [
        {
          vmid:"keywords",
          name: "keywords",
          content: "online shopping,online store,grocery store,online shopping pakistan,best online shopping website in pakistan",
        },
        {
          vmid:"description",
          name: "description",
          content: "Asanshopping is the best online shopping store in Pakistan where you can choose the right product along with the best price available on different shopping stores.",
        },
        {
          property: "og:title",
          content: "Best Online Shopping Store in Pakistan",
        },
        {
          property: "og:description",
          content: "Asanshopping is the best online shopping store in Pakistan where you can choose the right product along with the best price available on different shopping stores.",
        },
        {
          property: "og:image",
          content: "https://eshopapp.eyekyc.com/staticog/siteog.jpg",
        },
         { property: "og:type", content: "website" },
        { property: "og:url", content: "https://asanshopping.pk/" },
       
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  components: {
    "vue-pull-refresh": VuePullRefresh,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  methods: {
    onRefresh: function () {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve();
          console.warn("JJJJJ");
        }, 1000);
      });
    },
  },
};
</script>

<style lang="scss">
.main-content {
  padding: 0px 0px 0px !important;
}
</style>