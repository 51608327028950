<template>
  <div>
    <v-footer
      dark
      color="secondary"
      :class="{ 'd-none': $route.path == '/home-two' }"
    class="d-md-block d-none"
    >
      <v-container>
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5" sm="6">
            <v-avatar width="98" height="44" tile class="mb-5">
              <img src="@/assets/images/logos/Asaan-shopping-logo.png" alt="" />
            </v-avatar>
            <p class="footer-text mb-5">
             Asan Shopping App (www.asanshopping.pk) is just an e-commerce product search engine, a free service just like Google Shopping, which is not available in Pakistan and only helps Pakistani e-buyers smartly find their products in terms of saving product search time along with helping them identify and find the lowest priced product of their choice. Asan Shopping is not an e-commerce store, hence the owner of the Asan Shopping app or website (www.asanshopping.pk) is not responsible for any kind of loss or anything bad or negative related to e-commerce online transactions or purchases made by the user of the Asan shopping app or website.
            </p>
            <!-- <div class="d-flex flex-sm-nowrap flex-wrap">
            <a href="#" class="me-3 mb-3">
              <v-img
                max-width="130"
                src="@/assets/images/app-store/google-play.png"
              ></v-img>
            </a>
            <a href="#">
              <v-img
                max-width="130"
                src="@/assets/images/app-store/app-store.png"
              ></v-img>
            </a>
          </div> -->
          </v-col>

          <!-- <v-col cols="12" xl="2" lg="2" md="4" sm="6">
          <h2 class="mb-5">About Us</h2>
          <div v-for="(n, index) in aboutUs" :key="index" class="my-1">
            <a href="#" class="text-decoration-none footer-link-hover">{{
              n
            }}</a>
          </div>
        </v-col> -->
          <v-col cols="12" xl="3" lg="3" md="4" sm="6">
            <h2 class="mb-5">Services</h2>
            <div class="my-1">
              <a href="/" class="text-decoration-none footer-link-hover"
                >Home</a
              >
            </div>
            <div class="my-1">
              <a
                href="/brandHome"
                class="text-decoration-none footer-link-hover"
                >All Brands</a
              >
            </div>
            <div class="my-1">
              <a href="/blogs" class="text-decoration-none footer-link-hover"
                >Blogs</a
              >
            </div>
            <div class="my-1">
              <a href="/stores" class="text-decoration-none footer-link-hover"
                >Registered Stores</a
              >
            </div>
            <div class="my-1">
              <a href="/about" class="text-decoration-none footer-link-hover"
                >About Us</a
              >
            </div>
            <div class="my-1">
              <a
                href="/contact-us"
                class="text-decoration-none footer-link-hover"
                >Contact Us</a
              >
            </div>
            <div class="my-1">
              <a href="/stores" class="text-decoration-none footer-link-hover"
                >How to Register Your Store</a
              >
            </div>

            <!-- <div v-for="(n, index) in customerCare" :key="index" class="my-1">
            <a href="#" class="text-decoration-none footer-link-hover">{{
              n
            }}</a>
          </div> -->
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="6">
            <h2 class="mb-5">Contact Us</h2>
            <p class="footer-text">
              House no 126, near mirpur police station, Musa Zai Colony,
              Abbottabad, 22010
            </p>
            <p class="footer-text pb-3 mb-0">
              <v-icon small size="24px" left>mdi-email-outline</v-icon>
              info@asanshopping.pk
            </p>
            <p class="footer-text pb-3 mb-0">
              <v-icon small size="24px" left>mdi-headphones</v-icon
              >support@asanshopping.pk
            </p>

            <p class="footer-text">
              <v-icon small size="24px" left>mdi-cellphone</v-icon
              >+92-316-5730822
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-footer color="primary" padless class="secondaryFooter">
      <v-container>
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="d-flex align-center">
              <!-- <img src="./assets/Asaan-shopping-logo.png" height="36" /> -->
              <div class="ml-3 mt-2 white--text">
                © {{ new Date().getFullYear() }} —
                <strong class="mr-1"> Asan Shopping</strong> All Rights
                Reserved.
              </div>
            </div>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="float-right">
              <v-btn
                small
                text
                class="mr-2 white--text text-capitalize"
                to="/privacy"
                >Privacy</v-btn
              >
              <v-btn small text class="mr-1 white--text text-capitalize" to="/terms&conditions"
                >Terms</v-btn
              >
              <v-tooltip top>
      <template v-slot:activator="{ on }">
              <v-btn
              v-on="on"
              target="_blank"
               href="https://www.facebook.com/asanshopping.pk1"
                class="ml-1 secondary--text text-capitalize"
                elevation="0"
                x-small
                color="white"
                fab
                
              >
                <v-icon color="#3b5998" size="24"> mdi-facebook </v-icon> 
              </v-btn>
      </template>
      <span>Facebook</span>
              </v-tooltip>
              <v-tooltip top>
      <template v-slot:activator="{ on }">
              <v-btn
              v-on="on"
              target="_blank"
              href="https://www.instagram.com/asanshopping.pk/"
              class="ml-1 secondary--text text-capitalize"
                elevation="0"
                x-small
                color="white"
                fab
              >
                <v-icon color="#E1306C" size="24"> mdi-instagram </v-icon> 
              </v-btn>
      </template>
      <span>Instagram</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({}),
};
</script>
<style lang="scss">
a.footer-link-hover {
  color: #bdbdbd !important;
  &:hover {
    color: #fff !important;
  }
}
// .v-footer {
//   padding-top: 5rem;
//   padding-bottom: 5rem;
// }

@media screen and (max-width: 960px) {
 .secondaryFooter{
margin-bottom: 55px;
}
}
</style>
