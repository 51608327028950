<template>
  <v-app>
    <base-app-bar />
    <v-main class="main-content bg-body">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <footer-view />
    <!-- <mobile-navigation /> -->
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="secondary"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-app>
</template>
<script>
import BaseAppBar from "../layouts/base/AppBar";
import FooterView from "../layouts/base/Footer";
// import MobileNavigation from '../layouts/base/MobileNavigationBar'
export default {
  components: {
    BaseAppBar,
    FooterView,
    // MobileNavigation
  },
  data() {
    return {
      fab: false,
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
<style>
.main-content {
  padding: 0px 0px 0px !important;
}
@media screen and (max-width: 599px) {
  .v-btn--absolute.v-btn--bottom,
  .v-btn--fixed.v-btn--bottom {
    bottom: 60px;
  }
  .v-btn--fab.v-size--default {
    height: 40px;
    width: 40px;
  }
}
</style>
