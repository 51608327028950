import axios from "axios";
import store from "./index";
// export let domain = "http://172.16.10.59:8000";
export let domain = "https://sys.asanshopping.pk";
//  export let domain = "https://eshopback.eyekyc.com";

// export let url = domain + "/api/";
export let url = domain;
export const public_url = axios.create({
  baseURL: url,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

//define response interceptors
public_url.interceptors.response.use(
  (response) => {
    // store.commit("setNetworkErrorState", false);

    return Promise.resolve(response);
  },
  (error) => {
    if (!error.response) {
      window.scrollTo(0, 0);
      // store.commit("setNetworkErrorState", true);
    } else {
      // store.commit("setNetworkErrorState", false);
    }
    return Promise.reject(error);
  }
);