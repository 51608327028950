import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { public_url } from "./axios";
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
Vue.use(Vuex)


export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    // start asanshopping.pk

    productUrl: null,
    similarProducts: "",
    selectedCategory: null,
    selectedSubCategory: null,
    selectedStore: [],
    categories: null,
    brandCategory: null,
    brandSubCategory: null,
    // pageNumber:1
    //end asanshopping.pk

    selectedCategory: null,
  },
  getters: {
    //start asanshopping.pk
    getSelectedCategory: state => state.selectedCategory,
    getProductUrl: (state) => {
      return state.productUrl;
    },
    getSimilarProducts: (state) => {
      return state.similarProducts;
    },
    getSelectedCategory: (state) => {
      return state.selectedCategory;
    },
    getSelectedStore: (state) => {
      return state.selectedStore;
    },
    // getAllCategories:state=> state.categories,
    getBrandCategory: state => state.brandCategory,
    getBrandSubCategory: state => state.brandSubCategory,

    // getPageNumber:(state)=>{
    //   return state.pageNumber;
    // }
    //end asanshopping.pk

    // getProducts : state => state.products,
    // getCartProducts : state => state.newProducts,
    // getCategory : state => state.category,
    // getRatings : state => state.ratings,


  },
  actions: {
    //start asanshopping.pk
    getDashboardItems() {
      return public_url.get("/api/featured/?format=json");
    },
    getDetails({ }, payload) {
      var store_name = payload.storeName
      var detail_param = payload.detail_param
      return public_url.get(`/api/details/${store_name}/${detail_param}/?format=json`);
    },
    searchProduct({ }, payload) {
      return public_url.post("/api/search/?format=json", payload);
      // return public_url.post(`/search/page=${payload.pageNumber}/`, payload);
    },
    searchStore({ }, payload) {
      return public_url.post("/api/search/?format=json", payload);
    },
    similarProducts({ }, payload) {
      // console.log("requested_with  ",payload.requested_with, "product_name ",payload.product_name)
      var product_name = payload.product_name
      var requested_with = payload.requested_with
      return public_url.get(`/api/similar/${requested_with}/${product_name}/?format=json`);
    },
    similarBrandProducts({ }, payload) {
      var requested_with = payload.requested_with
      return public_url.get(`/brands/similar/${requested_with}/?format=json`);
    },
    sendMessage({ }, payload) {
      return public_url.post("/api/contact/?format=json", payload);
    },



    getCategories() {
      return public_url.get("brands/categories/?format=json");
    },
    // setCategories({commit},payload){
    //   commit('SET_CATEGORIES',payload)
    // },
    getSubCategories({ commit }, payload) {
      return public_url.get(`brands/categories/${payload}/?format=json`);
    },
    getProducts({ commit }, payload) {
      return public_url.get(`brands/categories/${payload.brandCatg}/${payload.brandSubCatg}/?format=json`);
    },
    brandsSlides() {
      return public_url.get("brands/sliders/?format=json");
    },
    brandsMenu() {
      return public_url.get("brands/menus/?format=json");
    }
    //end asanshopping.pk

    // addCart ({ commit }, data) {
    //   commit('ADD_CART', data)
    // },
    // removeCart ({ commit }, data) {
    //   commit('REMOVE_CART', data)
    // },
  },
  mutations: {
    //start asanshopping.pk
    setSelectedCategory: (state, payload) => state.selectedCategory = payload,
    setProductUrl: (state, payload) => {
      state.productUrl = payload;
    },
    setSimilarProducts: (state, payload) => {
      state.similarProducts = payload
    },
    setSelectedCategory: (state, payload) => {
      state.selectedCategory = payload
    },
    setSelectedSubCategory: (state, payload) => {
      state.selectedSubCategory = payload
    },
    setSelectedStore: (state, payload) => {
      state.selectedStore = payload
    },
    setBrandCategory: (state, payload) => {
      state.brandCategory = payload
    },
    setBrandSubCategory: (state, payload) => {
      state.brandSubCategory = payload
    },
    SET_CATEGORIES(state, payload) {
      state.categories = payload
    }

    // setPageNumber:(state,payload)=>{
    //   state.pageNumber=payload
    // }
    //end asanshopping.pk



    // ADD_CART (state, data) {  

    //   let productExist = false;

    //   state.newProducts = state.newProducts.map(product => {
    //     if(product.id == data.id){
    //       productExist = true
    //       product.qty += 1
    //     }
    //     return product
    //   })

    //   if(!productExist){
    //     data.qty += 1
    //     state.newProducts.push(data);

    //   }
    // },
    // REMOVE_CART (state, data) {
    //   data.qty -= 1
    //   if(data.qty == 0){
    //     let product = state.newProducts.filter(product => product.id != data.id) 
    //     state.newProducts = product
    //   }

    // },


  },

})



