<template>
    <div class="ecom-dropdownmenu pt-0 d-none d-lg-block">
                <ul  class="py-3 mb-0">
                     
      <!-- start=> set this list with working -->
        <!-- <li
        v-for="category in categories"
        :key="category.name"
        :value="category.value"
        @click="setCategory(category.name)"
        
      >
        <a>
          <v-avatar tile size="20" class="mr-2">
            <img :src="category.image" :alt="category.name" />
          </v-avatar>
          <p class="m-0 mb-0 flex-grow-1">{{ category.name }}</p>
        </a>
      </li> -->
      <!-- FROM STORE  -->
      <li
        v-for="category in categories"
        :key="category.name"
        :value="category"
        @click="setCategory(category.value)"
       
        
      >
      <!-- @mouseenter="SubCategories(category)" -->
        <a>
          <v-avatar tile size="20" class="mr-2">
            <img :src="category.image" :alt="category.name" />
          </v-avatar>
          <p class="m-0 mb-0 flex-grow-1 text-capitalize">{{ category.name }}</p>
        </a>
         <!-- <div  class="sub-menu">
           <ul class="p-3 mr-3 text-capitalize" v-if="subCategories.length==0" >
               <li>
             <p class="mb-0 flex-grow-1 text-capitalize">Loading...</p>
             </li>
           </ul>
                            <ul class="p-3 mr-3 text-capitalize" v-else>
                                <li v-for="catg in subCategories" :key="catg" :value="catg" @click="setSubCategory(catg)">
                                  <a >
                                 <p class="mb-0 flex-grow-1 text-capitalize">{{ catg }}</p>
                                 </a>
                                </li>
                            </ul>
         </div> -->
      </li>
      <!-- end -->
                 
                </ul>
            </div>
    
</template>
<script>
import { mapActions, mapMutations } from "vuex";
export default {
    data () {
        return {
            // start asasnshopping 
            selectedCategory:'',
            // categories:[],
            // subCategories:[],
             categories: [
      {
        name: "Electronic Devices",
        value: "electronic_devices",
        image: [require("@/assets/categoriesSVG/ElectronicDevices.svg")],
      },
      {
        name: "Electronic Accessories",
        value: "electronic_accessories",
        image: [require("@/assets/categoriesSVG/electronicAccessories.svg")],
      },
      {
        name: "TV & Home Appliances",
        value: "tv_&_home_appliances",
        image: [require("@/assets/categoriesSVG/TV&HomeAppliances.svg")],
      },
      {
        name: "Health & Beauty",
        value: "health_&_beauty",
        image: [require("@/assets/categoriesSVG/HealthBeauty.svg")],
      },
      {
        name: "Babies & Toys",
        value: "babies_&_toys",
        image: [require("@/assets/categoriesSVG/babiesToys.svg")],
      },
      {
        name: "Groceries,Home & Lifestyle",
        value: "groceries_home_&_lifestyle",
        image: [require("@/assets/categoriesSVG/groceriesPets.svg")],
      },
      {
        name: "Women's Fashion",
        value: "women_fashion",
        image: [require("@/assets/categoriesSVG/womenFashion.svg")],
      },
      {
        name: "Men's Fashion",
        value: "men_fashion",
        image: [require("@/assets/categoriesSVG/menFashion.svg")],
      },
      {
        name: "Watches,Bags & Jewelery",
        value: "watches_bags_&_jewelery",
        image: [require("@/assets/categoriesSVG/watchesBags.svg")],
      },
      {
        name: "Sports & Outdoor",
        value: "sports_&_outdoors",
        image: [require("@/assets/categoriesSVG/sports&outDor.svg")],
      },
      {
        name: "Automotives & Motorbikes",
        value: "automotives_&_motorbikes",
        image: [require("@/assets/categoriesSVG/automotiveBikes.svg")],
      },
    ],
            // end asanshopping 
          
        }
    },
      created(){
        this.setSelectedCategory(this.selectedCategory);
        // this.categories=this.$store.getters['getAllCategories']
    },
    methods:{
          ...mapMutations([
      "setSelectedCategory"
    ]),
         setCategory(catgName){
            this.selectedCategory=catgName
            this.setSelectedCategory(catgName)
        },
        // setSubCategory(catg){
        //   this.setSelectedSubCategory(catg) 
        // },
        // SubCategories(catgName){
        //   this.subCategories=[]
        //   this.$store.dispatch('getSubCategories',catgName).then((res)=>{
            
        //     this.subCategories=[...res.data.Subcategories]
        //     console.log("subCategories...",res,this.subCategories)
        //   })
        // }
    }
}
</script>
<style lang="scss" scoped>
$primary-500: #D23F57;
$primary-100: #FCE9EC;
$box-shadow: 0px 4px 16px rgb(43 52 69 / 10%);
$secondary: #2B3445;
$gray-900: #2B3445;


// ecomdropdown 
.ecom-dropdownmenu {
  padding-top: 10px;
  
  &.ecom-dropdownmenu-fixed{
      
    display: none;
    &.open {
      display: block;
    }
  }
  ul {
    background-color: rgb(255, 255, 255);
    
    padding: 0;
    width: 250px;
    max-width: 250px;
    box-shadow: $box-shadow;
    border: none;
    border-radius: 0.5rem;
    &.disable {
      li {
        // background-color: $gray-100;
        a{
          font-weight: bold;
          
         
        }
      }
    }
    li {
      position: relative;
      list-style-type: none;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between; 
        padding: 0.594rem 1.25rem;
        text-decoration: none;
        color: $gray-900;
      }
      &:hover > {
        a {
          background-color: $primary-100;
          color: $primary-500;
          i{
              color: $primary-500;
          }
        }
        .sub-menu {
          display: block;
          
        }
      }
      // child ul 
      .sub-menu {
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        padding-left: 1rem;
        display: none;
        z-index: 10;
        ul{
          background-color: white;
          padding: 0;
          border-radius: 0.5rem;
          box-shadow: $box-shadow;
          width: 100%;
          li {
            list-style-type: none;
          }
        }

        .mega-menu {
          min-width: 600px;
          box-shadow: $box-shadow;
          padding: 1.5625rem 1.25rem;
          border-radius: 0.5rem;
          background-color: white;
          ul {
            border: none;
            box-shadow: none;
            li {
              margin-bottom: 0.625rem;
              a {
                padding: 0;
                margin: 0;
              }
              &:hover {
                a {
                  background-color: white;
                  color: $primary-500;
                 
                }
              }
            }
          }
        }
        
      }
    }

  }
}
</style>